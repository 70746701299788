import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import localeIntl from './assets/intl/data.json';

import { func, axius } from './utils';
import { Loading } from './components';

import * as authAct from './store/auth/_authActions';
import * as dataAct from './store/data/_dataActions';

const Main = React.lazy(() => import('./Main'));
const TriPadi = React.lazy(() => import('./TriPadi'));
const Arbitrage = React.lazy(() => import('./Arbitrage'));
const Authenticate = React.lazy(() => import('./screens/authenticate/auth'));

class App extends React.Component {

  state = {
    loadingApp: false,
  }

  componentDidMount() {
    func.initialize();
    this.initApp();
  }

  initApp = () => {
    Promise.all([
      axius.get('users'),
      axius.get('categories'),
      axius.get('users', { id: this.props._auth.logg.id || '0' }),
    ]).then(res => {
      this.props.setSetSettings('users', res[0].status === 200 ? res[0].data : []);
      this.props.setSetSettings('categories', res[1].status === 200 ? res[1].data : []);
      res[2].status === 200 && this.props.signInSuccess(this.props._auth.token, res[2].data[0]);
    });
  }

  render() {
    const { loadingApp } = this.state;
    const { _auth: { authenticated }, _utils: { lang } } = this.props;

    return (
      <React.Fragment>
        <React.Suspense fallback={<Loading />}>
          <IntlProvider locale={lang} defaultLocale="en" messages={localeIntl[lang]}>
            <Router>
              <Route path='/survey/tripadi' exact={false} render={p => <TriPadi {...p} {...this.props} />} />
              <Route path='/survey/arbitrage' exact={false} render={p => <Arbitrage {...p} {...this.props} />} />

              {!this.props.history.location.pathname.includes('survey') && (
                <>
                  {loadingApp ? (<Loading />) : (authenticated ? (<Route render={() => <Main />} />) : (<Authenticate />))}
                </>
              )}
            </Router>
          </IntlProvider>
        </React.Suspense>
      </React.Fragment>
    );
  }

}

const mapStateToProps = (state) => ({
  _auth: state.auth,
  _utils: state.utils,
});

const mapDispatchToProps = (dispatch) => ({
  signInSuccess: (token, data) => {
    dispatch(authAct.signInSuccess(token, data));
  },
  setSetSettings: (key, value) => {
    dispatch(dataAct.setSetSettings(key, value));
  },
  signOutSuccess: () => {
    dispatch(authAct.signOutSuccess());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);