import axios from 'axios';
import { func } from '.';
import moment from 'moment';

const GitToken = `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`;
const GitBaseUrl = `https://api.github.com/`;

export const apnData = (obj) => {
    const body = new FormData();
    for (var p in obj) {
        if (p === 'file') {
            body.append('file[0]', obj[p]);
        } else if (p === 'image') {
            body.append('image[0]', obj[p]);
        } else {
            body.append(p, obj[p]);
        }
    }
    return body;
}

export const apiHeaders = (type = '') => {
    if (type === 'file') {
        return func.config.headersFile;
    } else {
        return func.config.headers;
    }
}


export const post = async (action, data = {}) => {
    const empty = action.includes('http:') || action.includes('https:') ? true : false;
    const url = ((empty === false) ? func.config[func.config.env].url + '?request=' + action : action);
    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
        });
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

export const get = async (action, data = {}) => {
    data['logg'] = func.getStorageJson('user').id;
    data['token'] = func.getStorage('token');
    const empty = action.includes('http:') || action.includes('https:') ? true : false;
    const url = ((empty === false) ? func.config[func.config.env].url + '?request=' + action : action);
    return axios({
        method: 'GET', url,
        params: data
    }).then(response => {
        const res = response.data;
        return res;
    }).catch(error => {
        return { status: 606, result: 'Network request failed', error };
    });
}

export const put = async (action, data = {}) => {
    const headers = apiHeaders();
    const empty = action.includes('http:') || action.includes('https:') ? true : false;
    const url = ((empty === false) ? func.config[func.config.env].url + action : action);
    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

export const delte = async (action, data = {}) => {
    const headers = apiHeaders();
    try {
        const response = await fetch(func.config[func.config.env].url + action, {
            method: 'DELETE',
            headers,
            body: JSON.stringify(data),
        });
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return { status: 606, result: 'Network request failed', error: error };
    }
}

export const postFile = async (action, data = {}) => {
    const headers = apiHeaders('file');
    try {
        const response = await fetch(func.config[func.config.env].url + action, {
            method: 'POST',
            headers,
            body: apnData(data)
        });
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return { status: 606, result: 'Network request failed', error: error };
    }
}


export const postGit = async (action, data = {}) => {
    const empty = action.includes('http:') || action.includes('https:') ? true : false;
    const url = ((empty === false) ? GitBaseUrl + action : action);
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Accept: `application/vnd.github+json`,
                Authorization: `Bearer ${GitToken}`,
                'X-GitHub-Api-Version': `2022-11-28`,
            },
            body: JSON.stringify(data),
        });
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        return { status: 606, result: 'Network request failed', error };
    }
}

export const getGit = async (action, data = {}) => {
    const url = GitBaseUrl + action;
    data['cache'] = moment().unix();
    return axios({
        method: 'GET', url,
        headers: {
            Accept: `application/vnd.github+json`,
            Authorization: `Bearer ${GitToken}`,
            'X-GitHub-Api-Version': `2022-11-28`,
        },
        params: data,
    }).then(response => {
        return {
            data: response.data,
            status: response.status,
        };
    }).catch(error => {
        return { status: 606, result: 'Network request failed', error };
    });
}