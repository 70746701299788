import React from 'react';
import moment from 'moment';
import { Modal } from 'antd';

export const config = {
    env: 'live',
    dbpref: 'bk_',
    version: require('../../package.json').version,
    local: {
        url: 'http://localhost/anchoratechs/bk-api/',
    },
    live: {
        url: 'https://bk-api.anchoratechs.com/',
    },
    apiToken: '',
    headers: {},
    headersFile: {},
};

export const app = {
    version: require('../../package.json').version,
}

export const initialize = () => {
    if (window.location.host.match(/localhost/i)) {
        config.env = 'live';
    } else {
        config.env = 'live';
    }
    config.apiToken = getStorage('token');

    config.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    config.headersFile = {
        'Accept': 'application/json',
    };
}

export const dates = {
    yr: moment().format('YYYY')
}


// ::: storage
export const setStorage = (key, value) => {
    if (key && value) {
        localStorage.setItem(config.dbpref + key, value);
    }
}
export const getStorage = (key) => {
    const value = localStorage.getItem(config.dbpref + key);
    return value || '';
}
export const setStorageJson = (key, value) => {
    if (key && value) {
        localStorage.setItem(config.dbpref + key, JSON.stringify(value));
    }
}
export const getStorageJson = (key) => {
    if (key) {
        const value = localStorage.getItem(config.dbpref + key);
        return JSON.parse(value) || '';
    }
}
export const delStorage = (key) => {
    if (key) {
        localStorage.removeItem(config.dbpref + key);
    }
}

export const mergeObj = (obj, src) => {
    for (var key in src) {
        if (src.hasOwnProperty(key)) obj[key] = src[key];
    }
    return obj;
}
export const getFileExtension = (filename) => {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext === null ? '' : ext[1];
}

// Spinners
export const fspinner = <div style={{ textAlign: 'center', color: '#999', lineHeight: 320 + 'px', width: 100 + '%' }}><i className="fa fa-spin fa-circle-o-notch fa-5x"></i></div>;
export const fspinner_sm = <div style={{ textAlign: 'center', color: '#999', lineHeight: 120 + 'px', width: 100 + '%' }}><i className="fa fa-spin fa-circle-o-notch fa-3x"></i></div>;
export const fspinner_xs = <i className="fa fa-spin fa-circle-o-notch"></i>;

export const redirect = (to) => {
    window.location = to;
}

export const generateOptions = (length, step = 1) => {
    const arr = [];
    for (let value = 0; value < length; value += step) {
        arr.push(value);
    }
    return arr;
};

export const hasR = (role) => {
    let user = getStorageJson('user');
    let myRoles = ((user.role || {}).data || '').split(',');
    return (myRoles.includes(role) || myRoles.includes('*'));
};

export const numberFormat = (number, decimal = 3) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: (decimal === 0 ? 0 : 2), maximumFractionDigits: decimal }).format(number);
}

export const loading = (content) => {
    return new Promise((resolve) => {
        content = <div dangerouslySetInnerHTML={{ __html: `<i class="fa fa-spin fa-spinner"></i> <span>${content}</span>` }} />
        const loading = Modal.info({
            icon: null,
            title: null,
            centered: true,
            content,
            width: '250px',
            okButtonProps: { style: { display: 'none' } },
            cancelButtonProps: { style: { display: 'none' } }
        });
        resolve(loading);
    });
}

export const alert = (title, content, props, onOK) => {
    Modal.confirm({
        icon: null,
        title,
        centered: true,
        content,
        okText: 'Okay!',
        width: '300px',
        okTextProps: { style: { display: 'none' } },
        cancelTextProps: { style: { display: 'none' } },
        ...props,
    });
}


export const chunk = (inputArray, chunkSize) => {
    return inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray
    }, []);
}

export const imgError = (e) => {
    e.target.src = '/assets/default.png';
}