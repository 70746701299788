import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import HttpsRedirect from 'react-https-redirect';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';
import configureStore, { history } from './store/_store';

import './index.less';
import './assets/scss/custom.scss';

import App from './App.jsx';

const store = configureStore();

const antdTheme = {
    token: {
        colorPrimary: '#5dc998',
        colorSecondary: '#122e40',
        borderRadius: 8,
        fontFamily: '"Libre Franklin", sans-serif',
    },
};

ReactDOM.render(
    <Provider store={store}>
        <HttpsRedirect>
            <ConfigProvider theme={antdTheme}>
                <App history={history} />
            </ConfigProvider>
        </HttpsRedirect>
    </Provider>, document.getElementById('root'));

serviceWorker.unregister();
