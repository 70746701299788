import { SIGNIN_SUCCESS, SIGNOUT_SUCCESS } from '../_types';
import * as func from '../../utils/functions';

export function signInSuccess(token, data) {
    func.setStorage('token', token);
    func.setStorageJson('user', data);
    return dispatch => {
        dispatch({
            type: SIGNIN_SUCCESS,
            payload: {
                data: data,
                token: token
            }
        });
    }
};

export function signOutSuccess() {
    return dispatch => {
        func.delStorage('token');
        func.delStorage('user');
        dispatch({ type: SIGNOUT_SUCCESS });
    }
};